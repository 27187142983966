.slx-nav {
  padding: 0 20px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.slx-nav > .logo {
  margin: 15px 0;
}
.slx-nav > .logo img {
  width: 200px;
  margin: 0.7em;
}
.slx-nav > .links {
  display: flex;
  margin-left: 1em;
}
.slx-nav > .pull-right {
  flex: 1;
  align-self: center;
  text-align: right;
}
