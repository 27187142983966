.view-session {
    padding: 40px 80px;
}
.view-session-fragments {
    display: flex;
    flex-wrap: wrap;
    margin: -1em;
}
.view-session-fragments > div {
    /* max-width: calc(100% / 3); */
    flex: 1;
    width: 100%;
    /* min-width: 300px; */
}
.view-session-fragments > div > div {
    margin: 1em;
    height: 300px;
}

.view-session-header {
    display: flex;
    margin-bottom: 1em;
    height: 36px;
    justify-content: space-between;
}

.view-session-header__breadcrumbs {
    margin-top: -1em;
}

.navbar__breadcrumbs {
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.navbar__breadcrumbs > .breadcrumb:last-child > span {
    font-weight: 700;
    cursor: default;
}
.navbar__breadcrumbs > .breadcrumb {
    cursor: pointer;
}
.navbar__breadcrumbs > .breadcrumb:hover > span {
    font-weight: 700;
}
.breadcrumb__connector {
    padding: 0 0.5em;
}
.audio-response-summary__response-header,
.text-response-summary__response-header {
    display: flex;
    width: 100%;
    align-items: center;
}
.audio-response-summary__prompt,
.audio-response-summary__prompt > div,
.text-response-summary__prompt {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(50vw);
}
.audio-response-summary__icon,
.text-response-summary__icon {
    line-height: inherit;
    margin: 0 0.1rem;
}

.text-response-summary {
    display: flex;
    flex-direction: column;
}

.text-response-summary__response {
    display: flex;
    align-items: center;
    height: 228px;
}

.text-response-summary__response-text {
    flex: 1;
    overflow: wrap;
    text-align: center;
    padding: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 100%;
    min-width: 12em;
}

.text-response-summary__responses-visualization {
    flex: 1;
    text-align: center;
    height: 100%;
}

.audio-response-summary__response {
    flex: 1;
}
.audio-response-summary__models {
    max-width: 20em;
    flex: 1 1;
    padding: 20px 24px 20px 24px;
    display: flex;
    flex-direction: column;
    height: 300px;
    margin: -16px -24px -16px 2em;
    border-left: 2px solid #ccc;
    background: #fcfcfc;
}


.audio-response-summary__model {
    display: flex;
    flex: 1;
}
.audio-response-summary__model > div {
    padding: 3px;
    flex: 1
}

.model-summary__name {
    min-width: 7em;
    white-space: nowrap;
}
.model-summary__value {
    min-width: 7em;
    font-weight: 700;
    white-space: nowrap;
}

.audio-response-summary {
    display: flex;
    height: 260px;
}