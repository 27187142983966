.search-text-field__container {
  display: inline-block;
  flex: 1;
  text-align: left;
}
.search-text-field__container > .search-text-field__icon {
  position: absolute;
  margin: 5px 0 0 10px;
  font-size: 1.3em;
  opacity: 0.4;
}
.search-text-field__container > .search-text-field__input {
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 10px 5px 35px;
  width: 100%;
  border: 1px solid #F0F0F0;
  font-size: 1em;
}
.search-text-field__container > .search-text-field__input:focus {
  border: 1px solid #4275FF;
  outline: none;
}