.image-selector__image-grid {
    margin: 1em 0;
}

div.image-selector__grid-items {
    /* display: flex;
	flex-direction: column; */
    display: flex;
    flex-wrap: wrap;
	flex-direction: row;
	margin-top: 2em;
	margin-bottom: 2em;
}

.image-selector__grid-item {
    text-align: center;
    /* flex: 1; */
    /* height: 200px;
	width: 200px; */
}

.image-selector__grid-item > div {
    text-align: center;
    flex: 1;
    height: 100%;
    width: 100%;
}

.image-selector__image-grid .paper {
	display: flex;
	align-items: center;
    height: 200px;
    width: 200px;
    /* line-height: 200px; */
    margin: 1em auto;
	cursor: pointer;
}

.image-selector__image-grid .paper span {
	flex: 1;
}

.image-selector__image-grid .paper .logo {
	height: 100%;
	width: 100%;
}

input#upload-image-file {
    display: none;
}

.image-selector__image-grid .image-selector__grid-item .uploadItem span {
    width: 100%;
}

.editor-header__logo-input .logo {
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .image-selector__image-grid .paper {
        width: 85vw;
    }
    .image-selector__image-grid .paper img {
        max-width: 85vw;
    }
}