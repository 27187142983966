.slx-nav-link {
  display: flex;
  flex-direction: column;
  width: 124px;
  cursor: pointer;
}

.slx-nav-link > button {
  align-self: center;
  width: 124px;
  background: none;
  padding: 0;
  margin: 0;
  color: #3F71F7;
  border: 0;
  display: block;
  padding: 30px 10px;
  font-size: 1.2em;
  border-radius: 0 0 2px 2px;
  cursor: pointer;
}
.slx-nav-link > button:focus {
  outline: none;
}
.slx-nav-link > svg {
  display: none;
}

.slx-nav-link.active:hover button {
    background-color: #3860c9;
}
.slx-nav-link.active:hover svg {
    fill: #3860c9;
}
.slx-nav-link.active > button {
  background-color: #3F71F7;
  color: white;
}
.slx-nav-link.active > svg {
  display: block;
  fill: #3F71F7;
}

@media only screen and (max-width: 680px) {
  #root .slx-nav {
    padding: 0;
    margin-bottom: -30px;
  }
  #root .slx-nav > .logo {
    margin: 1em 0.5em;
  }
  #root .home-actions {
    padding: 0;
    margin: 0 0 1em 0;
  }
  #root .slx-nav-link {
    display: none;
  }
  #root .slx-nav > .pull-right {
    display: none;
  }
  #root .Footer {
    padding: 1em;
    min-height: auto;
  }
  #root .Footer .footer__copyright > p {
    font-size: 0.8em;
  }
}