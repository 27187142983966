.create-survey__container {
  display: inline-block;
  flex: 1;
  text-align: right;
}
.create-survey__container > .create-survey__button {
  margin-right: 15px;
  /* border: 1px solid #4174FC; */
  /* border-radius: 5px; */
  /* color: #4174FC; */
}