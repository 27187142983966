.surveyunpublishedcard {
  text-align: center;
}
.surveyunpublishedcard .surveyactions {
  border-top: 1px solid #ddd;
}
.surveyunpublishedcard .surveyactions > button {
  flex: 1;
  border-radius: 0;
  border: 1px solid #ddd;
}
.surveyunpublishedcard .surveyactions > button > span {
  text-transform: uppercase;
}
.surveyunpublishedcard .surveyactions > button.left {
  color: #3f71f7;
  border-color: #3f71f7;
}
.surveyunpublishedcard .surveyactions > button.left > span {
  text-align: left;
}
.surveyunpublishedcard .surveyactions > button.right {
  color: #fff;
  background-color: #F1C13C;
  border: 0;
}
.surveyunpublishedcard .surveyactions > button.right > span {
  text-align: right;
}
.surveyunpublishedcard hr {
  margin: 0;
}