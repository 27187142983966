.consent-form__container {
    display: flex;
    flex-direction: column;
}

.consent-form__actions {
    padding-top: 1em;
    margin: 0.25rem;
    display: flex;
}

.consent-form__actions>button:first-child {
    margin-left: 2rem;
}

.consent-form__language {
    flex: 1;
    overflow-wrap: normal;
    overflow-y: auto;
}

.consent-form__prompt {
    font-weight: 400;
    margin: 1rem 2rem;
    display: grid;
    grid-template-columns: 2em 1fr;
    font-size: 1.25em;
}

.consent-form__prompt-text {
    margin: calc(0.5rem + 0.22em) 0;
    padding-left: 0.25rem;
}
