.clipboard-link {
    display: grid;
    grid-template-columns: 114px 1fr 114px;
    margin: 0.5em;
    padding: 0.5em;
    font-size: 1.3em;
    align-items: center;
    border: 1px solid #3977ff;
    border-radius: 4px;
}

.clipboard-link .clipboard-link__text {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    font-size: inherit;
    background: none;
    flex: 1;
    text-align: center;
}

.clipboard-link .clipboard-link__text-container {
    position: relative;
    grid-column: 2;
    flex: 1;
    display: flex;
}


.clipboard-link .clipboard-link__text.clipboard-link--success {
    position: absolute;
    left: 0;
    width: 100%;
    user-select: none;
    animation: 0.7s ease-out 0.1s 1 forwards ghostUp;
}


.clipboard-link .clipboard-link__button.clipboard-link__copy {
    user-select: none;
    outline: none;
    font-size: 0.8em;
    grid-column: 1;
    /* font-size: inherit; */
    /* margin: 0 12px 0 0; */
    /* width: fit-content; */
    padding: 0 1em;
    display: flex;
    align-content: center;
}

.clipboard-link .clipboard-link__button.clipboard-link__clipboard {
    margin: 0 1em;
    grid-column: 3;
}

.clipboard-link .clipboard-link__button {
    user-select: none;
    outline: none;
    font-size: inherit;
    margin: 0 1em;
    width: fit-content;
    display: flex;
    align-content: center;
}

.clipboard-link .clipboard-link__button svg {
    height: 1em;
    width: auto;
}

@keyframes ghostUp {
    from {
        top: 0;
        opacity: 1;
        display: inherit;
    }
    to {
        top: -6em;
        opacity: 0;
        display: none;
    }
}

/* MOBILE SELECTORS */
.clipboard-link.clipboard-link__is-mobile {
    display: flex;
}

.clipboard-link.clipboard-link__is-mobile .clipboard-link__button.clipboard-link__copy {
    margin: 0 auto;
}

.clipboard-link.clipboard-link__is-mobile .clipboard-link__text-container {
   position: absolute !important;
   top: -9999px !important;
   left: -9999px !important;
}
/* END MOBILE SELECTORS */