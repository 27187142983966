body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-family: sans-serif;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app-content {
    flex-grow: 1;
}

.app-content.app-content__fullscreen {
    height: 100vh;
}

/* Setting the font size of inputs to 16px or more prevents most automatic zooming on input elements. */

#root input[type="text"],
#root input[type="number"],
#root input[type="email"],
#root input[type="tel"],
#root input[type="password"] {
    font-size: max(1em, 16px);
}
