.survey-consent-screen {
    display: flex;
    flex: 1;
}

.survey-consent-screen__header {
    font-weight: 400;
    font-size: 1.66em;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    margin: 0 auto;
}

.survey-consent-screen__header .showdown {
    text-align: center;
}

.survey-consent-screen__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1em;
}

.survey-consent-screen__description {
    text-align: left;
    /* margin-bottom: 20px; */
    padding: 1rem;
    font-size: 1.2em;
    line-height: 1.5em;
}

.survey-consent-screen .consent-form__container {
    flex: 1;
    max-width: 800px;
    margin: 0 auto;
    width: calc(100vw - 1em);
}

.survey-consent-screen .consent-form__language {
    margin-top: 1rem;
    min-height: 300px;
    background: #eaeaea;
}

.survey-consent-screen .consent-form__actions {
    padding-bottom: 2rem;
}

.survey-consent-screen .consent-form__prompt .consent-form__check {
    padding: 0.25em;
    font-size: 1em;
}

.survey-consent-screen .consent-form__prompt .consent-form__check svg,
.survey-consent-screen .consent-form__prompt .consent-form__check span {
    font-size: 1em;
}

.survey-consent-screen .consent-form__prompt .showdown {
    text-align: left;
}

.survey-consent-screen .consent-form__confirm-action {
    font-size: 1em;
}


@media screen and (max-width: 600px),
screen and (max-height: 600px) {
    .survey-consent-screen {
        font-size: max(0.8em, 16px);
    }

    .survey-consent-screen .survey-consent-screen__header {
        padding: 1rem;
    }

    .survey-consent-screen .consent-form__prompt {
        margin: 0;
    }

}
