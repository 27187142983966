/* COMPONENT: SURVEY */
.surveyContainer {
    background: none;
    padding: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.surveyjs {
    margin: 0 auto;
    padding: 1rem 6rem;
    background-color: none;
    counter-reset: section;
    max-width: 56em;
}

@media only screen and (max-width: 680px) {
    .surveyjs {
        margin: 0 auto;
        padding: 1em 2em;
        background-color: none;
        counter-reset: section;
    }
}

.surveyjs .sv_nav {
    text-align: center;
    margin-top: 100px;
    padding: 0;
    height: 100%;
    min-height: 100px;
    border-top: none;
}

/* BEGIN BUTTON STYLING */
.btn.btn-primary,
.surveyjs .sv_nav .sv_complete_btn {
    cursor: pointer;
    background-color: #3f71f7;
    color: white;
    height: auto;
    min-height: 36px;
    width: auto;
    min-width: 64px;
    float: right;
    border: none;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    padding: 0.5em 1em;
    font-size: 1em;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border-radius: 4px;
    text-transform: uppercase;
    -webkit-border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.surveyjs .card-block h3 {
    line-height: 211px;
    height: 211px;
}

.surveyjs h4 {
    margin-top: 50px;
}

.sv_p_title {
    font-weight: 700;
    cursor: initial !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
}

/* QUESTION */

.sv_qstn {
    padding: 0 !important;
    overflow: visible;
}

.sv_qstn label {
    font-size: 1em;
    margin-bottom: 0;
}

.sv_qstn h5:before {
    counter-increment: section;
    content: "."counter(section);
    position: absolute;
    left: -3.5em;
    margin: 0;
    padding: 0;
    width: 3em;
    direction: rtl;
    display: block;
    /* padding-right: 0.5em; */
    /* font-size: inherit; */
    /* top: 3px; */
}

.sv_row:nth-child(n + 10) .sv_qstn h5:before {
    left: -3.125em;
}

.surveyjs h5 {
    font-size: 1.125em;
    font-weight: 400;
    margin: 1em 0;
    /* padding: 0.5em 0; */
    border: 0;
}

.sv-q-col-1 {
    min-height: 30px;
}

/* CHECKBOXES/RADIO */
.sq-root-radiogroup .sq-label {
    grid-template-columns: 1em 0 1fr;
}

.sq-root-cb .sq-label {
    grid-template-columns: 1em 1fr;
}

/* Align with line-height */
.sq-root-cb .sq-label .checkbox-material,
.sq-root-radiogroup .sq-label .circle {
    margin-top: max(0px, calc(0.11em - 3px));
}

.sq-root-radiogroup .sq-label,
.sq-root-cb .sq-label {
    cursor: pointer;
    display: grid;
}

/* .sq-label input,
.sq-label span {
    vertical-align: middle;
} */

.sq-label input[type="radio"],
.sq-label input[type="checkbox"] {
    display: none;
}

.sq-label .circle,
.sq-label .checkbox-material {
    /* Margin-to to account for 1.2 lineheight. */
    /* margin-top: 0.1em; */
    display: inline-block;
    height: 1em;
    width: 1em;
    position: relative;
    /* top: 3px; */
    border: 3px solid #ccc;
    margin-left: 0;
}

.checked .circle,
.checked .checkbox-material {
    background-color: #ccc;
    border-color: #222 !important;
}

.sq-label>span>span {
    margin-left: 0 !important;
}

.sq-label>span:last-child {
    margin-left: 0.75em;
    margin-top: -0.225em;
    margin-bottom: 0.225em;
}

.sq-label .circle {
    border-radius: 0.5em;
}

.surveyjs .checkbox:hover,
.surveyjs .radio:hover {
    /*opacity: 0.8;*/
}

.surveyjs table input[type="radio"] {
    cursor: pointer;
}

.surveyjs table input[type="radio"]:hover {
    opacity: 0.8;
}

.surveyjs table td {
    padding: 20px 10px;
}

.surveyjs table th {
    font-size: 1.25em;
    text-transform: uppercase;
    width: 105px;
    padding: 0 3px;
}

/* RATING BUTTONS */
.sq-root-rating .sv_q_rating_min_text,
.sq-root-rating .sv_q_rating_max_text {
    display: none;
}

.sq-item {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}

@media only screen and (min-width: 681px) {
    .sq-root-rating {
        /* Bump out the second row. (Since it has a negative margin.) */
        text-indent: -3px;
        padding-left: 3px;
    }
}

.sq-root-rating {
    position: relative;
}

.sq-root-rating .sq-item {
    position: relative;
    z-index: 1000;
    display: inline-block;
    cursor: pointer;
    /* height: 2em; */
    line-height: 3em;
    min-width: 93px;
    padding: 0 12px;
    text-align: center;
    border: 3px solid #ccc;
    margin-left: -3px;
}

.sq-root-rating .sq-item.active {
    background-color: rgba(204, 204, 204, 0.5);
    font-weight: 700;
    border: 3px solid #222;
    z-index: 1001;
}

.sq-root-rating .sq-item.active:first-child {
    border-left: 3px solid #222;
}

.sq-root-rating .sq-item:first-child {
    border-left: 3px solid #ccc;
    margin-left: 0px;
}

/* MATRIX */
.sq-root-matrix tr:nth-child(even) {
    background-color: rgba(204, 204, 204, 0.2);
}

/* text input fields */
.sv_container textarea.sv_q_other,
.sv_container input[type="text"] {
    width: 100%;
    height: auto;
    padding: 1em;
    border: 3px solid #ccc;
    /* border-radius: 5px; */
    font-size: 1em !important;
}

.sv_container textarea.sv_q_other.empty,
.sv_container input[type="text"].empty {
    background-color: red;
}

/* .sv_container .sv_q_title .sv_q_num,
.sv_container .sq-title .sv_q_num, */
.sv_container .sv_q_title span,
.sv_container .sq-title span {
    display: none;
}

.sv_container .sv_q_title span:nth-child(3),
.sv_container .sq-title span:nth-child(3) {
    display: block;
}

.sv_q_erbox.sv_qstn_error_top {
    font-size: 0.875em;
    color: rgba(220, 25, 25, 0.8);
    margin: 1em;
}

.sv_container .sv_row {
    position: relative;
    margin-top: 1em;
}

.sv_container .sv_q_title,
.sv_container .sq-title {
    display: flex;
}

.sq-label .checkbox-material {
    margin-left: 0;
}
