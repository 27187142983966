.available-download__included {
  grid-column: 1;
}
.available-download__title {
  grid-column: 2;
  padding: 1em 0.5em;
}
.available-download__formats-container {
  grid-column: 3;
  align-self: center;
}

.file-inclusion .available-download {
  display: grid;
  grid-template-columns: 48px 1fr calc(48px * 1.5);
}