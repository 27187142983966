.CreateSurveyForm {
    padding: 40px 80px;
}

.CreateSurveyForm .actions {
    padding: 20px 0 40px 0;
    text-align: left;
}

.CreateSurveyForm .actions.pull-right {
    text-align: right;
}

.CreateSurveyForm .actions > button {
    border-radius: 0;
    border: 1px solid #ddd;
    margin-right: 10px;
}

.CreateSurveyForm .actions > button.secondary {
    color: #fff;
    background-color: #f1c13c;
    border: 1px solid #f1c13c;
}

.CreateSurveyForm .actions > button.primary {
    color: #3f71f7;
    border-color: #3f71f7;
}

.CreateSurveyForm .card {
    padding: 15px;
    margin: 15px 0;
}

.CreateSurveyForm .card .form-group {
    margin: 30px 0;
}

.actions {
    padding: 15px 0;
}

.create-survey__inner-container {
    display: flex;
    flex-direction: column;
    padding: 2em;
}

.create-survey__editor {
    /* border: 1px dashed rgba(0, 0, 0, 0.5); */
    min-height: 300px;
}

.create-survey__selected-logo {
    text-align: center;
    margin-bottom: 15px;
}

.create-survey__selected-logo img {
    max-width: 100%;
    max-height: 100px;
}

/* button.create-survey__button {
    margin-left: 1em
} */

/* .create-survey__actions {
  position: fixed;
  right: 2em;
} */

.create-survey__main {
    display: flex;
    flex-direction: row-reverse;
}

.create-survey__main-content {
    display: flex;
    flex: 1;
    flex-direction: column;
}

/* .create-survey__sidebar-content {
  left: 2em;
  position: fixed;
  width: 22em;
} */

@media only screen and (max-width: 680px) {
    .create-survey__actions {
        position: inherit;
        right: auto;
    }
    .create-survey__main {
        flex-direction: column;
    }
    .create-survey__main-content {
        position: inherit;
        left: auto;
    }
    .create-survey__sidebar-content {
        position: inherit;
        width: auto;
    }
    .editor-pane__controls-left .editor-pane__text {
        display: none;
    }
    .editor-header__container label,
    .editor-header__container button {
        flex: 1;
    }
    .editor-pane__controls-left .editor-pane__control-button {
        min-width: 20px;
    }
    .editor-add-screen-content button {
        flex: 1;
    }
    .editor-audio-standard-screen__recorder {
        display: flex;
        flex-direction: column;
        margin: 0.5em;
    }
    .editor-audio-standard-screen__recorder > div {
        margin: 1em 0;
        flex: 1;
    }
    .editor-pane__screen-container > div {
        width: 100%;
    }
    .editor-pane__screen-container > div button {
        margin: 0.5em;
    }
    .home-actions {
        flex-direction: column-reverse;
    }
    .search-text-field__container {
        flex: 1;
    }
    .create-survey__container > .create-survey__button {
        display: flex;
        flex: 1;
        width: 100%;
        margin: 1em 0;
    }
    .app-content .home-view,
    .app-content .view-session,
    .app-content .ViewSurvey {
        padding: 1em;
    }
    .app-content .ViewSurvey .view-survey__download-results {
        display: none;
    }
    .app-content .navbar__breadcrumbs {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
    }
    .view-survey__header-center {
        padding: 1em 0;
    }
    .survey-summaries .survey-summaries__survey-summary {
        min-width: 100%;
        margin: 1em 0;
    }
    .app-content
        .ViewSurvey
        .view-survey-responses__sessions
        .session-summary__container,
    .app-content .ViewSurvey .view-survey-overview-chart {
        min-width: 100%;
    }
    .app-content .view-session-fragments {
        flex-direction: column;
    }
    .app-content .survey-result-response {
        flex-direction: column;
    }
    .app-content .text-response-summary__response {
        flex-direction: column;
        height: auto;
    }
    .app-content .view-session-fragments > div > div,
    .app-content .audio-response-summary {
        height: auto;
        flex-direction: column;
    }
    .app-content .survey-result-response__timestamp {
        display: none;
    }
    .app-content .audio-response-summary__models {
        display: flex;
        margin: 0;
        max-width: 100%;
        border: 1px solid #efefef;
    }
    .app-content .text-response-summary__question-number,
    .app-content .audio-response-summary__question-number {
        color: #555;
    }
    .app-content .audio-response-summary__icon,
    .app-content .text-response-summary__icon {
        color: #aaa;
        margin: 0 0.5em;
        position: relative;
        top: 2px;
    }
    .app-content .audio-response-summary__prompt {
        max-width: calc(100vw - 8em);
    }
    .app-content .survey-result-response__transcription {
        display: block;
        height: auto;
        line-height: 1.75em;
        font-size: 1em;
    }
    .app-content .survey-result-response__play-button {
        margin: 1em 0;
    }
    .app-content .survey-result-response__play-button button {
        background: #027ee7;
        width: 100%;
        border-radius: 8px;
        font-size: 1em;
    }
    .app-content .survey-result-response__play-button button > span {
        flex: 1;
        color: white;
    }
    .app-content .play-button__container .play-button__icon {
        flex: 2;
        text-align: right;
        padding: 0 0.5em 0 0;
    }
    .app-content .play-button__container .play-button__help-text {
        display: flex;
        flex: 3;
        padding: 0 0 0 0.5em;
    }
    #root .app-content__fullscreen .top {
        flex-direction: column;
    }
    #root .app-content__fullscreen .fragment-audio_standard .top {
        display: none;
    }
    #root .app-content__fullscreen .fragment-0 .top {
        display: flex;
    }
    #root .app-content__fullscreen .top > .top__context {
        display: none;
    }
    #root .app-content__fullscreen .top {
        height: auto;
    }
    /* #root .app-content .top__title {
    display: none;
  } */
    #root .app-content__fullscreen .top > .top__logo {
        width: 100%;
    }
    #root .app-content__fullscreen .top > .top__title {
        margin: 0.5em;
    }
    #root .app-content__fullscreen .top > .top__logo img {
        width: 100%;
        margin: 0;
    }
    #root .app-content__fullscreen .top > .top__logo.logo {
        width: 100%;
        /* height: 128px; */
        height: 138px;
        padding: 1vh;
    }
    #root .survey-audio-screen {
        padding-bottom: 30vh;
    }
    #root .survey-audio-screen__control {
        flex: 40 1;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: rgba(250, 250, 250, 0.9);
        -webkit-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.1);
        max-width: 1024px;
        margin: 0 auto;
        padding-top: 2vh;
    }
    #root .surveyjs .sv_nav {
        margin: 1.5em 0;
    }
    #root .surveyjs h5 {
        /* font-size: 1.5em; */
    }
    #root .Footer .footer__links .footer__link {
        display: none;
    }
    #root .Footer .footer__links .footer__link.terms-of-use,
    #root .Footer .footer__links .footer__link.privacy-policy {
        display: block;
    }
}

@media only screen and (max-width: 680px) {
    #root .sq-root-rating .sq-item {
        width: calc(100% - 6px);
        margin-top: 0.2em;
        margin-left: 0;
    }
    #root .sq-root-rating .sq-item.active {
        margin-left: 0;
    }
}

.editor-add-screen-header {
    max-width: 440px;
}

.editor-add-screen-content {
    margin-top: -1em;
}

.create-survey__inner-container {
    display: grid;
    grid-template-columns: 23em 1fr 23em;
    grid-template-rows: 3em auto 1fr;
    padding: 0 2em 1em 2em;
}

/*
 * Triple Column
 */

.create-survey__actions {
    position: sticky;
    top: 1em;
    height: max-content;
    margin-top: 1.5em;
    grid-column: 3;
    padding-left: 0.75em;
    padding-right: 0.75em;
    display: flex;
    max-width: 440px;
    justify-content: space-between;
    z-index: 1000;
}

.create-survey__main-content {
    grid-column: 2;
}

.create-survey__sidebar-content {
    position: sticky;
    top: 0;
    height: max-content;
    grid-column: 1;
    padding-right: 1em;
    margin-top: 21px;
}

.create-survey__actions,
.create-survey__main-content,
.create-survey__sidebar-content {
    grid-row: 1 / span 3;
}

@media only screen and (min-width: 1300px) {
    .create-survey__actions {
        position: sticky;
        top: 1em;
        height: max-content;
        margin-top: 1.5em;
        grid-column: 3;
        padding-left: 0.75em;
        padding-right: 0.75em;
        display: flex;
        max-width: 440px;
        justify-content: left;
        z-index: 1000;
    }
    .create-survey__actions button:not(:first-child) {
        margin-left: 1em;
    }
}

/*
 * Single Column
 */

@media only screen and (max-width: 899px) {
    .create-survey__inner-container {
        /* Support screens thinner than 460px. */
        grid-template-columns: 1fr 1fr 1fr;
        padding-left: 2em;
        padding-right: 2em;
        padding-top: 2em;
        padding-bottom: 2em;
    }
    .create-survey__actions {
        grid-row: 1;
        top: 0;
        padding: 1em 2em;
        margin-top: -1em;
        margin-right: 0;
        margin-left: -2em;
        margin-bottom: 2em;
        background-color: #fff;
        width: 100vw;
        max-width: initial;
        box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
    .create-survey__main-content {
        padding-top: 1em;
        grid-row: 2;
    }
    .create-survey__sidebar-content {
        grid-row: 3;
        margin-top: 0;
        padding-right: 0;
    }
    .create-survey__actions,
    .create-survey__main-content,
    .create-survey__sidebar-content {
        grid-column: 1 / span 3;
    }
    #root .editor-fragment-surveyjs__question-prompt {
        width: initial;
    }
}
@media only screen and (max-width: 680px) {
    #root .editor-fragment-surveyjs__question-prompt {
        margin-right: 1.5em;
    }
    #root .editor-fragment-surveyjs__prompt {
        margin: 0;
    }
    #root .editor-fragment-surveyjs__prompt-number {
        width: 1em;
        margin-right: 0.5em;
        margin-top: 1.5em;
    }
    #root .editor-fragment-surveyjs__remove-question-button {
        margin: 0.5em 0 0 -1.25em;
    }
    #root .editor-pane__controls svg {
        height: 1.35em;
        width: 1.35em;
    }
}

/*
 * Double Column
 */

@media only screen and (max-width: 1300px) and (min-width: 900px) {
    .create-survey__actions {
        position: sticky;
        top: 1em;
        grid-row: 1;
        grid-column: 1;
        margin-top: 0;
    }
    .create-survey__main-content {
        grid-row: 1 / span 2;
        grid-column: 2 / span 2;
    }
    .create-survey__sidebar-content {
        position: sticky;
        top: 3em;
        height: max-content;
        grid-row: 2 / span 2;
        grid-column: 1;
        padding-left: 1em;
        padding-right: 0;
        margin-top: -15px;
    }
    .editor-add-screen-header {
        padding-left: 1em;
        padding-right: 1em;
    }
}

.editor-set-default-size {
    margin-top: 1.5em;
    text-align: left;
    /* display: flex; */
    /* flex-direction: vertical; */
}

.editor-set-default-size .multi-button-selection {
    text-align: left;
    display: flex;
}
