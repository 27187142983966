.Footer {
  background-color: #3977ff;
  min-height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  position: relative;
}

.footer__app-version {
  position: absolute;
  bottom: 1em;
  right: 1em;
  color: white;
  font-size: 10px;
  text-transform: uppercase;
}

.footer__links {
  display: block;
  text-align: center;
}
.footer__links a {
  margin-top: 0.5em;
  white-space: nowrap;
}
.footer__social {
  display: flex;
  align-self: center;
}
.footer__copyright--text {
  white-space: normal;
}

.Footer > div {
  flex-grow: 1;
  align-items: center;
  flex-wrap: wrap;
  padding: 1em;
}

.Footer a, .Footer p {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
  display: inline-block;
}
.Footer a:hover {
  color: #ddd;
}
.Footer svg {
  height: 32px;
  width: 32px;
  fill: #9CBBFF;
} 