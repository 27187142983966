.snackbar-download {
  width: 100%;
}
.snackbar-download {
  display: flex;
  flex-direction: row;
}

.snackbar-download .snackbar-download__status {
  flex-grow: 1;
}

.snackbar-download__progress {
  position: absolute;
  top: 1em;
  right: 1em;
}

/* .snackbar-download__container .MuiSnackBarContent-message {

} */