.ViewSurvey {
    padding: 40px 80px;
}

.ViewSurvey .card h3 {
    color: #0290ff;
}

.view-survey__no-responses-text {
    text-align: center;
    padding: 3em 0 0 0;
    width: 100%;
}

.view-survey__header,
.view-survey-overview__container,
.view-survey-responses__container,
.view-survey-responses__sessions,
.view-survey-responses__footer {
    display: flex;
}

.view-survey-responses__sessions,
.view-survey-overview__container {
    margin: -1em;
    flex-wrap: wrap;
}

.view-survey__header,
.view-survey-overview__container,
.view-survey-responses__header,
.view-survey-responses__sessions,
.view-survey-responses__footer {
    margin-bottom: 1em;
}

.view-survey-responses__sessions .session-summary__container,
.view-survey-overview-chart {
    width: calc(100% / 3);
    min-width: 300px;
}

.view-survey-responses__sessions .session-summary__card,
.view-survey-overview-chart > * {
    height: 300px;
    margin: 1em;
}

.flex-card {
    display: flex;
    flex-direction: column;
}

.chart-card__title {
}

.chart-card__chart {
    margin-top: -13px;
    flex-grow: 1;
}

.card-block {
    /*
    Note: This lets you build inside the card with no padding,
      but I'm not 100% sold on the technique.
   */
    margin: -16px -24px 0px -24px;
    height: 150px;
    padding: 16px 24px;
    color: white;
    background: #3f71f7;
    display: flex;
    text-align: center;
    align-content: center;
    flex-direction: column;
}

.card-block__title {
    margin: -1rem;
    font-size: 90px;
    font-weight: 700;
    text-shadow: 0px 1px 3px rgb(1, 109, 192), 0px 2px 5px rgb(1, 109, 192);
}

.card-pairs-table {
    /* display: table;
    margin: auto;
    margin-top: calc((142px - (1.5em * 4)) / 2); */
    display: table;
    margin: auto;
    margin-top: calc(( 180px - (2.3em * 4)) / 2);
    font-size: 16px;
}

.card-pairs-table__pair {
    display: table-row;
    height: 1.5em;
}

.card-pairs-table__key {
    display: table-cell;
    text-align: right;
}

.card-pairs-table__value {
    display: table-cell;
    text-align: left;
    font-weight: 700;
    padding: 0.15em;
    padding-left: 0.25em;
}

.view-survey-responses__container {
    flex-direction: column;
}

.view-survey-responses__header {
    display: flex;
}

.view-survey__header-title {
    flex-grow: 1;
    /* height: 36px; */
}

.view-survey__download-results {
    white-space: nowrap;
    height: 36px;
}

.view-survey__header-breadcrumbs {
    margin-top: -1em;
}

.view-survey__header-center {
    flex-grow: 1;
    text-align: center;
    align-self: center;
}

.view-survey-responses__search-bar-container {
    flex: 1;
}

.session-summary__card {
    display: flex;
    flex-direction: column;
}

.session-summary__content-container {
    flex: 1;
    overflow-y: auto;
}

.session-summary__action-container {
    text-align: center;
}

.session-summary__action-container > button {
    font-size: 1em;
    width: 100%;
}

.session-summary__state {
    font-size: 0.8em;
    font-weight: 700;
}

.session-summary__state--complete,
.session-summary__state--complete ~ .session-summary__header-title {
    color: #3f71f7;
}

.session-summary__state--incomplete,
.session-summary__state--incomplete ~ .session-summary__header-title {
    color: #ccc;
}

.session-summary__header {
    display: flex;
    min-height: 2em;
    justify-content: space-between;
}

.session-summary__state {
}

.session-summary__header-title {
    display: flex;
}

.session-summary.session-summary__header-title h6 {
    font-weight: 700;
}

.session-summary__progress {
}

.session-summary__device-info {
    display: flex;
}
.session-summary__device-info button {
    margin-top: -8px;
}

.session-summary .survey-result-response__transcription {
    overflow: hidden;
    /* white-space: nowrap;
  text-overflow: ellipsis; */
    /* This the line height times the number of lines we want to show */
    height: calc(1.46429em * 3 + 12px);
    position: relative;
}

.session-summary .survey-result-response__transcription:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 1.46429em;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 50%
    );
}

.session-summary.session-summary__progress {
}

.session-summary__user-agent {
    max-width: 300px;
    padding: 20px;
}

/* Clipboard Link */

/* .clipboard-link__button {
    fill: #3F71F7;
} */

.view-survey__header .clipboard-link {
    max-width: 100%;
    margin: 0;
    padding: 0.5em 0;
    margin-top: 0.5em;
}

@media only screen and (max-width: 1000px) {
    .view-survey__header .clipboard-link {
        max-width: 100%;
        margin: 0;
        padding: 0.5em 0;
        margin-top: 0.5em;
    }
    .view-survey__header .clipboard-link .clipboard-link__text-container {
        grid-column: 2 / span 2;
    }
    .view-survey__header .clipboard-link .clipboard-link__clipboard {
        display: none;
    }
}
