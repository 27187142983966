.named-divider {
  display: flex;
  align-items: center;
}
.named-divider .named-divider__name {
  white-space: nowrap;
  margin-right: 25px;
  line-height: 1em;
  font-size: 1.1em;
  font-weight: 500;
}
.named-divider hr {
  border-top-color: #5885FE;
  border-top-style: solid;
  margin: auto;
  width: 100%;
}
