.download-builder-modal {

}

.download-builder-modal .download-builder-modal__content-container {
  width: 600px;
  height: 60vh;
  max-height: 45em;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.download-builder-modal .download-builder-modal__header {
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  /* align-items: center; */
}

.download-builder-modal .download-builder-modal__header .download-builder-modal__header-back {
  height: max-content;
  margin-right: 1rem;
}
.download-builder-modal .download-builder-modal__header .download-builder-modal__header-title {
  flex: 1;
}

.download-builder-modal .download-builder-modal__name-input {
  /* margin-right: 1em; */
}

.download-builder-modal .export__container {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem;
  overflow: hidden;
}

.download-builder-modal .export__details-container,
.download-builder-modal .export__actions-container {
  display: flex;
  flex-direction: column;
}

.download-builder-modal .export__details-container {
  flex: 1;
}

.download-builder-modal .export__actions-container .export__button {
  margin-bottom: 0.5rem;
}

.download-builder-modal .export__actions-container .export__button .export__button-delete {
  flex: 1;
  align-self: flex-end;
}


.download-builder-modal .download-progress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.download-builder-modal .export__status {
  position: absolute;
  top: 0;
  right: 2rem;
}
.download-builder-modal .export__status:before {
  content: "Status: "
}

.download-builder-modal .download-builder-modal__export-options-panel {
  /* border-top: none; */
}

.download-builder-modal .download-builder-modal__export-options-panel.download-builder-modal__export-options-panel--expanded {
  flex: 1;
  overflow-y: auto;
}

.download-builder-modal .download-builder-modal__export-options-panel.download-builder-modal__export-options-panel--collapsed {
  background: #f5f5f5;
}

.download-builder-modal .download-builder-modal__previous-exports-panel {
}

.download-builder-modal .download-builder-modal__previous-exports-panel.download-builder-modal__previous-exports-panel--expanded {
  flex: 1;
  overflow-y: auto;
}

.download-builder-modal .download-builder-modal__previous-exports-panel.download-builder-modal__previous-exports-panel--expanded.download-builder-modal__previous-exports-panel--report {
  flex: auto;
}

.download-builder-modal .download-builder-modal__previous-exports-panel.download-builder-modal__previous-exports-panel--collapsed {
  background: #f5f5f5;
}

.download-builder-modal .download-builder-modal__export-options {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.download-builder-modal__export-options-section-title {
  /* align-self: center; */
  /* padding-left: 48;x; */
  padding: 8px 16px;
}

.download-builder-modal .download-builder-modal__export-options-panel.download-builder-modal__export-options-panel--expanded .expansion-panel-summary {
  /* display: none; */
}

.download-builder-modal .file-inclusion,
.download-builder-modal .export-options {
  margin-left: -15px;
}
.download-builder-modal .expansion-panel-details {
  padding-top: 0px;
  margin-top: -0.5rem;
}
.download-builder-modal__name-input fieldset {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.download-builder-modal .download-builder-modal__build-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 56px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-shadow: none;
}
