.survey-summaries .survey-summaries__group {
  display: flex;
  flex-wrap: wrap;
}

.survey-summaries .survey-summaries__group-container {
}

.survey-summaries .survey-summaries__group-title {
  margin: 0.5em 1em;
}

.survey-summaries .survey-summaries__survey-summary {
  /* TODO: Make subcomponents responsive so this value can be lowered. */
  min-width: 350px;
  margin: 1em;
  flex: 1;
}

.flexbox-lastline-fix {
  visibility: hidden !important;
  height: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
