.editor-add-screen {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 0 0 33.3333%;
}

div.editor-add-screen .editor-add-screen__choice {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.editor-add-screen-content {
    /* display: flex;
  justify-content: center;
  flex-wrap: wrap; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.editor-add-screen__choice .editor-add-screen__choice-icon {
    margin: 10px;
    width: 35px;
    height: 35px;
    color: #3977ff;
}

.editor-add-screen__choice .editor-add-screen__choice-title {
    font-size: 0.8rem;
    color: #3977ff;
}

.editor-add-screen-header {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 0;
    text-align: center;
}

.editor-add-screen-header h6 {
    padding: 0.15em 1em;
    color: #777;
    white-space: nowrap;
}

.editor-add-screen-content button,
.editor-add-screen-content div {
    display: flex;
    margin-top: 20px;
    width: 100px;
    height: 100px;
    flex-direction: column;
}

.editor-add-screen-content div {
    margin-left: 0.5em;
    margin-right: 0.5em;
    padding: 5px 16px;
}

.editor-add-screen-content button span {
    flex-direction: column;
}
