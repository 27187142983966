.survey-result-response {
  display: flex;
  flex-direction: row;
}

.survey-result-response__play-button {
  height: 48px;
  flex: 1;
  text-align: left;
}
.survey-result-response__play-button svg {
  font-size: 32px;
}

.survey-result-response__id {
  flex: 4;
  color: #4E4E4E;
  text-align: left;
  padding: 12px;
  height: 48px;
  display: none;
}

.survey-result-response.fullview .survey-result-response__id {
  display: block;
}


.survey-result-response__transcription {
  flex: 8;
  color: #4E4E4E;
  text-align: left;
  padding: 12px;
  height: 48px;
}

.survey-result-response__timestamp {
  /* flex: 2; */
  text-align: center;
  color: #C4C9CF;
  padding: 12px;
  height: 48px;
}

.survey-result-response.fullview .survey-result-response__timestamp {
  flex: 3;
}
