.editor-header__container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* height: 100px; */
  /* width: 100%; */
  /* margin: 15px 0; */
  /* border: 1px solid transparent; */
  /* transition: border 0.1s; */
  /* padding: 1px; */
  flex-direction: column;
}

.editor-header__actions {
  display: flex;
  align-self: center;
  flex-wrap: wrap;
}

.editor-header__container label, .editor-header__container button {
  /* margin: 0 30px; */
  width: 215px;
  color: #999;
}

.editor-header__container label button {
  margin: 0;
  width: auto;
}

/* .editor-header__container .MuiButton-label-53 {
  color: #999;
} */
.editor-header__container--incomplete {
  /* border: 1px dashed rgba(0,0,0,0.5); */
}

.editor-header__container .editor-header__title-input {
  margin: 1em;
  max-width: 440px;
}

#root .editor-header__container .editor-header__title-input input[type="text"] {
  font-size: 2em;
}

.editor-header__container .uploadInput {
  display: none;
}

.editor-header__container .editor-header__background-input {
}

.editor-header__title-input {

}

.editor-header__button:disabled .editor-header__button-icon {
  color: #999;
}

.editor-header__container .editor-header__button-icon {
  margin: 10px;
  width: 35px;
  height: 35px;
  color: #3977ff;
}
.editor-header__button:disabled .editor-header__button-text {
  color: #999;
}

.editor-header__container .editor-header__button-text {
  flex: 1;
  color: #3977ff;
  font-weight: 400;
}

.editor-header__container .editor-header__button {
  margin: 0.5em;
}

.editor-header__container button.editor-header__button > span {
  height: 50px;
}
