.available-download__container {
    display: flex;
    flex-direction: column;
    /* width: 18em; */
    margin: auto;
}
.available-download__container > div {
    display: flex;
    align-items: center;
}
.available-download__container .available-download__formats-container {
    margin: 0 0 0 1.5em;
}

.available-download__title {
    flex: 1;
}

.step__content-container {
    /* width: 600px; */
}

.step__buttons-container {
    display: flex;
    padding: 1em 1em 0 0;
}

.download-builder-preview__container {
    display: flex;
    flex-direction: column;
}

.download-builder-preview__file-container {
    display: flex;
    align-items: center;
}

.archives-available {
    flex-direction: column;
}

.archive__container {
    display: flex;
    align-items: flex-start;
    /* display: block; */
}

.archive__name {
    margin: 0.5em 0 0.5em 1em;
}

.archive__download-button {
    padding: 0.5em;
    width: 8em;
}