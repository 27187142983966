.surveyContainer {
    font-family: "Karla", sans-serif;
}

.finishedSurvey,
.survey-audio-screen .description {
    font-family: "Karla", sans-serif;
    font-weight: 700;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
    margin-bottom: 50px;
    font-size: 28px;
    padding-bottom: 10px;
}

.survey-audio-screen .description {
    text-align: center;
    line-height: 45px;
    margin-bottom: 300px;
    border-bottom: none;
}

/* .container {
  margin: 0 auto;
  margin-top: 150px;
  max-width: 1140px;
} */

/*.description {
  font-family: "Karla", sans-serif;
  font-size: 30px;
  text-align: center;
  line-height: 45px;
}*/

#analyser {
    width: 100%;
    height: 400px;
    position: fixed;
    bottom: 0;
    z-index: -1;
}

#analyser.hide {
    height: 1px;
}

#record {
    border: 2px solid white;
    background-color: red;
    color: white;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    position: fixed;
    top: 75%;
    left: 50%;
    margin: -35px 0 0 -50px;
    outline: none;
    font-size: 20px;
    font-weight: bold;
    z-index: 1000;
    cursor: pointer;

    transition: height 0.3s;
    transition: width 0.3s;
    transition: border-radius 0.5s;
}
#record:hover,
#record:active {
    opacity: 0.9;
}

#record.stoppable {
    border-radius: 10%;
}

.recorder.description {
    margin-bottom: 300px;
}

.app-content__fullscreen .top {
    display: flex;
    align-items: center;
    height: 108px;
    border-bottom: 0.25em solid #555;
}
.app-content__fullscreen .top > .top__title {
    flex: 1;
}
.app-content__fullscreen .top > .top__logo {
    width: 300px;
    height: 100%;
}
.app-content__fullscreen .top > .top__logo.logo {
    padding: 0.25em;
}
.app-content__fullscreen .top > .top__logo img {
    width: 100%;
    margin: 1em;
}
.app-content__fullscreen .top > .top__context {
    width: 300px;
}
.app-content__fullscreen .surveyContainer {
    overflow-y: auto;
}

.survey-header__subtitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.app-content__fullscreen .surveyContainer {
    flex: 1;
    padding: 0;
}

.survey-complete-screen {
    text-align: center;
    line-height: 1.6em;
    font-size: 1.2em;
    padding: 1em;
}

.powered-by-surveylex__container {
    position: fixed;
    right: 0;
    bottom: 0;
}
.powered-by-surveylex__container > a {
    border-radius: 5px 0 0 0;
}
.powered-by-surveylex__container .powered-by-surveylex__typography {
    text-transform: initial;
    font-weight: bold;
    margin: 0 0.5em;
}
.powered-by-surveylex__container .powered-by-surveylex__typography img {
    position: relative;
    top: 2px;
}

.app-content .survey-complete-screen__powered-by-button {
    margin: 3em 0;
}
.app-content .survey-complete-screen__powered-by {
    width: 50vw;
    flex-direction: column;
    padding: 1em;
}
.app-content .survey-complete-screen__logo img {
    height: 30px;
}
.app-content .survey-complete-screen__call-to-action {
    font-size: 1.75em;
    text-transform: initial;
}

#root .app-content .powered-by-surveylex__container {
    display: none;
}

@media only screen and (max-width: 600px) {
    #root .app-content .powered-by-surveylex__container {
        display: none;
    }
    #root .app-content .survey-complete-screen__powered-by {
        width: 80vw;
        padding: 1em 0.5em;
    }
    #root .app-content .survey-complete-screen__logo img {
        height: 30px;
    }
    #root .app-content .survey-complete-screen__call-to-action {
        font-size: 1.35em;
    }
}
