.export-option {
  grid-column: 1;
}
.export-option__title {
  grid-column: 2;
  padding: 1em 0.5em;
}
.export-option {
  display: grid;
  grid-template-columns: 48px 1fr;
}