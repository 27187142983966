.top {
    background-color: white;
    text-align: center;
}

.top .headerLogos {
    margin: 0 auto;
    padding: 2em 1em;
}

.headerLogos .companyLogo {
  max-width: 400px;
  width: 100%;
}