@import '../SurveyLexUserNav/SurveyLexUserNav.css';

.expanding-gap {
    flex:1;
}
.slx-nav__welcome {
    z-index: 1;
}
.slx-nav__welcome .links{
    flex: inherit;
}