.editor-template-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.editor-template-list .add-template-button {
    flex: 1;
    min-width: fit-content;
}
