.editor-consent-screen__container {
    width: 80%;
    max-width: 500px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
}

.editor-consent-screen__container textarea {
    /* Fix for a MUI bug. */
    left: 0;
}

.editor-consent-screen__container .editor-consent-screen__title {
    /* display: block; */
    /* width: 50%; */
    margin-bottom: 1em;
    align-self: center;
    width: calc(100% - 1.5em);
}

.editor-consent-screen__container .consent-form__prompt {
    flex: 1;
    max-width: none;
    width: calc(100% - 0.5em);
    margin: 0;
}

.editor-consent-screen__container .consent-form__prompt .consent-form__check {
    /* margin-top: 1rem; */
    margin-top: 1.75rem;
}

.editor-consent-screen__container div.editor-consent-screen__prompt {
    margin-top: 10px;
}

.editor-consent-screen__container .consent-form__actions>button:last-child {
    margin-right: 0;
}
