.download-builder-file-summary__container {
    display: flex;
    align-content: center;
    flex-direction: column;
}

.download-builder-file-summary {
    margin: 1em;
    display: table;
}

.download-builder-file-summary__file-summary {
    padding: 0.25em;
    display: table-row;
}

.download-builder-file-summary__file-summary-description-total {
    display: table-cell;
    text-align: right;
    padding: 0.25em;
}
.download-builder-file-summary__file-summary-description {
    display: table-cell;
    text-align: left;
    padding: 0.25em;
}

.download-builder-file-summary__no-files {
}