.editor-pane__container {
    margin: 2em auto;
    max-width: 38em;
}

.editor-pane__controls {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    padding: 0 15px;
    justify-content: space-between;
    width: 100%;
}

/* adding a margin on the icon + text buttons (only on left at the moment) */
/* should probably use MUI withStyles or target non-IconButtons in the future */
.editor-pane__controls-left svg {
    margin-right: 5px;
}

.editor-pane__controls-left button {
    color: #777;
}

.editor-pane__controls-right button {
    padding: 8px;
}

.editor-pane__controls > button:disabled {
    background-color: #999;
}

.editor-pane__controls > button:hover {
    background-color: #3860c9;
}

.editor-pane__screen-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    /* overflow-y: auto; */
}

.editor-pane__screen-container > div {
    width: 80%;
    max-width: 500px;
    min-width: 200px;
}