.analyser {
  width: 100vw;
  height: 75vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.analyser__canvas {
  width: 100%;
  height: 100%;
}

.analyser.hide {
  height: 1px;
}

.survey-audio-screen {
  display: flex;
  flex: 1;
}

.survey-audio-screen__container {
  flex: 1;
  align-content: center;
  text-align: center;
  font-size: 1em;
  display: flex;
  flex-direction: column;
}

.survey-audio-screen__prompt {
  position: inherit;
  min-height: 40vh;
  padding: 0 0.5em;
  margin: 0;
  display: flex;
  align-self: center;
  /* align-items: center; */
  overflow-y: auto;
  height: 58vh;
  /* flex: 70 1; */
}

.survey-audio-screen__control {
  position: inherit;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 30 1;
  min-height: 30vh;
}

.survey-screen .survey-audio-screen__button .survey-audio-screen__button-text {
  color: white;
  font-weight: 500;
  /* font-size: 20px; */
}

.survey-audio-screen__button {
  border: 2px solid white;
  background-color: #3F71F7;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  outline: none;
  z-index: 1000;
  cursor: pointer;
  margin: 1em 0;

  transition: height 0.3s;
  transition: width 0.3s;
  transition: border-radius 0.5s;
}

.survey-audio-screen__button svg {
  font-size: 1.75em;
  position: relative;
  top: 5px;
  left: 1px;
}

.survey-audio-screen__button:hover,
.survey-audio-screen__button:active {
  opacity: 0.9;
}

.survey-audio-screen__button:disabled {
  cursor: not-allowed;
  background-color: grey;
}

.survey-audio-screen__button.survey-audio-screen__button--recording {
  background-color: #F73F3F;
  border-radius: 10%;
}

.survey-audio-screen__button.survey-audio-screen__button--recording:disabled {
  cursor: not-allowed;
  /* TODO: Consider visual differences for audio that can or can't be stopped early. */
  /* border-radius: 50%; */
  /* background-color: #F73F3F; */
}

.survey-audio-screen__button.survey-audio-screen__button--stopped {
  border-radius: 10%;
}

.recorder.description {
  margin-bottom: 300px;
}

#root .fragment-audio_standard .showdown {
  margin: 3em 1em;
}

.audio-error__permissions-directions {
  margin: 2em auto;
  display: block;
  max-width: 100%;
  height: auto;
}

.audio-error__content p,
.audio-error__content h6,
.audio-error__content h5 {
  margin: 1em auto;
  font-size: 1em;
}

.audio-error__content p:first-child {
  margin-top: 0;
}

.survey-audio-screen .showdown {
  display: flex;
  flex-direction: column;
  align-self: center;
}

@media only screen and (max-width: 600px) {
  .top {
    display: none;
  }

  .audio-error__permissions-directions {
    display: none;
  }

  .survey-audio-screen__prompt {
    height: auto;
  }
}

@media only screen and (max-height: 600px) {
  .top {
    display: none;
  }
}

.survey-audio-screen__skip {
  cursor: pointer;
  visibility: hidden;
}

.survey-audio-screen__skip.survey-audio-screen__skip--skippable {
  visibility: visible;
}

.audio-confirmation-modal {
  border-bottom: 5px solid rgba(0, 0, 0, 0.54);
}

.audio-confirmation-modal button {
  font-size: 1.2em;
}

.audio-confirmation-modal button:first-child {
  color: rgba(0, 0, 0, 0.54);
}

.audio-confirmation-modal__playback {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.audio-confirmation-modal__playback audio {
  width: 100%;
  /* max-width: 400px; */
}

.audio-confirmation-modal__text-container {
  margin-left: 2em;
  margin-right: 2em;
}

.audio-confirmation-modal__text {
  margin-left: 2em;
  margin-right: 2em;
}

div.we-cant-hear-you {
  font-size: 0.875em;
}
