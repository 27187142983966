.editor-fragment__text-surveyjs {
    width: 80%;
    max-width: 500px;
    min-width: 200px;
}

.editor-fragment-surveyjs__prompt {
    display: flex;
    align-items: center;
    margin-left: -4em;
}

.editor-fragment-surveyjs__form-group {
    width: 100%;
}

.editor-fragment-surveyjs__prompt-number {
    width: 3.5em;
    margin-right: 1em;
    align-self: flex-start;
    margin-top: 1em;
}

.editor-fragment-surveyjs__add-question-button-container {
}

.editor-fragment-surveyjs__add-question-button {
}

.editor-fragment-surveyjs__add-choice-button,
.editor-fragment-surveyjs__add-rating-button {
    display: flex;
}

div.editor-fragment-surveyjs__add-choice-button button,
div.editor-fragment-surveyjs__add-rating-button button {
    width: 100%;
}

.editor-fragment-surveyjs__question .editor-fragment-surveyjs__content {
    display: flex;
    flex-direction: column;
}

.editor-fragment-surveyjs__question .editor-fragment-surveyjs__value {
    position: relative;
    /* margin: 0.5em 1em; */
    margin: 0.5em 0em 0.5em 2.3em;
}

.editor-fragment-surveyjs__question .editor-fragment-surveyjs__response-area {
    margin: 0.5em 1em;
}

button.editor-fragment-surveyjs__clear-value {
    position: absolute;
    margin: -0.333em 0.333em;
    /* top: 0;
    left: -2.666em; */
    top: 0.6em;
    left: -2.25em;
}

.editor-fragment-surveyjs__add-question-label {
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
}
.editor-fragment-surveyjs__add-question-actions {
    text-align: center;
}

.editor-fragment-surveyjs__json-import {
    display: none;
}

.editor-fragment-surveyjs__question {
    position: relative;
    margin-bottom: 1.5em;
}

button.editor-fragment-surveyjs__remove-question-button {
    position: absolute;
    margin: 0.333em;
    top: 0;
}

button.editor-fragment-surveyjs__clear-value--checkbox svg {
    color: white;
    height: 0.75em;
    width: 0.75em;
}

button.editor-fragment-surveyjs__clear-value--checkbox span:first-child {
    margin: 0.2em;
    background-color: grey;
}

@media only screen and (max-width: 600px) {
    #root .editor-fragment-surveyjs__prompt-number {
        width: 1em;
        margin-right: 0.5em;
        margin-top: 1.5em;
    }
    #root .editor-fragment-surveyjs__prompt-number h5 {
        font-size: 1em;
    }
    #root .editor-fragment-surveyjs__prompt {
        margin: 0;
    }
    #root .editor-fragment-surveyjs__remove-question-button {
        margin: 0.5em 0 0 -1.25em;
    }
    #root .editor-fragment-surveyjs__question-prompt {
        margin-right: 1.5em;
    }
    #root .editor-fragment-surveyjs__question .editor-fragment-surveyjs__value {
        margin-left: 2.25em;
    }
    #root .editor-fragment-surveyjs__clear-value {
        margin: 0 0.5em;
    }
}
