.user-menu__container {
  display: flex;
  flex-direction: row-reverse;
}

.user-menu {
  width: fit-content;
}

.user-menu > .user-menu__avatar {
  display: inline-block;
  margin: 0 10px;
}
.user-menu > .user-menu__full-name {
  display: inline-block;
  position: relative;
  top: -15px;
}
.user-menu > .expand-more {
  position: relative;
  top: -5px;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
}
.user-menu > .expand-more:focus {
  outline: none;
}

.user-menu {
  cursor: pointer;
}
