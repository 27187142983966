.showdown {
  text-align: left;
  font-size: 1em;
}

.showdown p {
  margin: 0;
}

.showdown img {
  max-width: 100%;
  height: 45vh;
}

.showdown pre {
  padding: 4px 8px;
  overflow-y: auto;
  background: white;
}

.showdown-video-embed {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.showdown-video-embed iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
