.textresponsesummary {
  text-align: center;
}

.text-response-summary__text {
  padding: 0.25em;
}

.text-response-summary__text.text-response-summary__text--short {
  font-weight: 700;
  font-size: 1.5em;
}

.text-response-summary__text.text-response-summary__text--long {
  font-weight: 500;
  font-size: 0.875em;
}