.playbutton {
  text-align: center;
}
.playbutton.play {
  color: #027EE7;
}

.play-button__container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.play-button__container .play-button__help-text
.play-button__container .play-button__icon {
  flex: 1;
}
.play-button__container .play-button__help-text {
  display: none;
}