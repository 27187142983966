.home-view {
  padding: 0 60px;
  margin-bottom: 2em;
}
.home-actions {
  display: flex;
  padding: 15px 0 15px 15px;
}

.home-view.tutorial-video {
  margin: 2em auto;
  height: 60vh;
}

#root .home-view.tutorial-video iframe {
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 460px;
  display: block;
  margin: 0 auto;
  border: 10px solid #222;
}