.editor-fragment-info {
    width: 80%;
    max-width: 500px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
}

.editor-fragment-info__title {
    margin-bottom: 0.5em;
    align-self: center;
    width: calc(100% - 1.5em);
}