.editor-audio-standard-screen__container {
    margin-top: 30px;
    width: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.editor-audio-standard-screen__form-group {
    width: 100%;
}

.editor-audio-standard-screen__prompt {
    width: 100%;
    max-width: 500px;
    min-width: 200px;
}

.editor-audio-standard-screen__recorder {
    margin-top: 3em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.editor-audio-standard-screen__recorder input {
    width: 100px;
    text-align: center;
}

.editor-audio-standard-screen__record-button {
    background-color: rgba(0, 0, 0, 0.3) /*red*/;
    color: white;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    border: 0;
    font-size: 20px;
    font-weight: bold;
    cursor: not-allowed;
    outline: none;
}

.editor-audio-standard-screen__record-button:hover {
    opacity: 0.9;
}

.editor-audio-standard-screen__flowchart {
    text-align: center;
    margin-top: 2em;
    margin-bottom: -1em;
}

.editor-audio-standard-screen__require-label {
    text-align: left;
}
