.survey-fragment-info__inner-container {
    max-width: 800px;
    margin: 0 auto;
    width: calc(100vw - 1em);
}

.survey-fragment-info__header {
    font-weight: 400;
    font-size: 1.66em;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    margin: 0 auto;
}

.survey-fragment-info__header .showdown {
    text-align: center;
}

.survey-fragment-info__content {
    flex: 1;
    position: relative;
    font-weight: 400;
    font-size: 1.2em;
    /* max-width: calc(100% - 7em); */
    min-height: 300px;
    /* margin: 2em 2em; */
    margin: 0 auto;
    margin-top: 1rem;
    width: 100%;
    background: #eaeaea;
}

.survey-fragment-info__content .showdown {
    text-align: left;
    padding: 1rem;
}

.survey-fragment-info .survey-fragment-info__actions {
    padding-bottom: 2rem;
    margin: 3rem 0;
    display: flex;
}

.survey-fragment-info .survey-fragment-info__actions .survey-fragment-info__next-action {
    font-size: 1em;
}


@media screen and (max-width: 600px),
screen and (max-height: 600px) {
    .survey-fragment-info {
        font-size: max(0.8em, 16px);
    }

    .survey-fragment-info__header {
        padding: 1rem;
    }

    .survey-fragment-info__actions>button:last-child {
        margin-right: 0.5rem;
    }
}
