.survey-result-card {
    text-align: center;
}

.survey-result-card--archived {
    opacity: 0.4;
    pointer-events: none;
}

.survey-result-card__title-container {
    display: flex;
    flex-direction: row;
}
.survey-result-card__title {
    flex: 6;
}
.survey-result-card__card-menu {
    flex: 1;
    position: relative;
    top: -0.7em;
    right: -0.7em;
}

.survey-result-card.surveyactions {
    border-top: 1px solid #ddd;
}
.survey-result-card.surveyactions > button {
    flex: 1;
    /* border-radius: 0; */
    /* border: 1px solid #ddd; */
}
.survey-result-card.surveyactions > button > span {
    text-transform: uppercase;
}
.survey-result-card.surveyactions > button.left {
    color: #3f71f7;
    border-color: #3f71f7;
}
.survey-result-card.surveyactions > button.left > span {
    text-align: left;
}
.survey-result-card.surveyactions > button.right {
    color: #fff;
    background-color: #f1c13c;
    border: 0;
}
.survey-result-card.surveyactions > button.right > span {
    text-align: right;
}
.survey-result-card hr {
    margin: 0;
}

.survey-result-card__action {
    margin-top: 1em;
}

.survey-result-card__action > button {
    font-size: 1em;
    width: 100%;
}

.survey-result-card__chart {
    margin: -1em -1.5em 0 -1.5em;
}

.survey-result-card .completion-rate {
    color: rgba(0, 0, 0, 0.54);
    margin-top: 0.5em;
}
